h1,
label,
input,
button,
span,
p,
.ListItemText,
.ListItem,
.List {
    font-family: "Roboto", sans-serif;
}

.SignInButton {
    border-radius: 5px;
    margin-top: 40px;
}

.TextField {
    margin-left: 10px;
    margin-right: 10px;
    margin: 8
}

.Alert {
    color: blueviolet
}

.hoverButtonWhite :hover {
    color: rgb(255, 255, 255) !important;
  }

.SignInBox {
    background-color: blueviolet;
    min-width: 40%;
    max-width: 400px;
}

.padText {
    margin-top: 30px;
}

@media only screen and (min-width: 600px) {
    .divLeftMobile {
        /*margin-left: 245px;*/
    }
    .toolbarMobileShow {
        visibility: hidden;
    }
    .drawerMobileShow {
        visibility: visible;
    }
}
