
body {
  margin: 0 auto;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.div-flex {
  display: flex;
  justify-content: center;
  /* background: black; */
}

.centered {
  margin-top: 20px;
  text-align: center;
  /* background: black; */
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  /* height: 20vmin; */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
